
import { defineComponent, ref } from 'vue';

import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import Multiselect from '@vueform/multiselect';

import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Nid Report',
  components: {
    axios,
    Form,
    Datatable,
    SearchBar,
    Swal,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      load: false,
      tabLoad: false,
      loading: false,
  moment: '' as any,
      institutes: [] as any,
      association: [] as any,
      start_date: '' as any,
      end_date: '' as any,
      btnCheck: false,
      entity_id: [] as any,
      institute_id: '' as any,
      shownidReport: false,
      componentKey: 0,
      actionActive: false,
      nidReportInfo: [] as any,
      nidReportAll: [] as any,
      nidListInfo: [] as any,
       entityInfos: [],
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      optionsTPartner: [] as any,
      tabIndex: ref(0),
      TotalEnroll: 0 as any,
      TotalNid: 0 as any,
      nidListTotal: 0 as any,
      nidreportstatus: false,
      nidliststatus: false,
      nidreportallstatus: false,
    };
  },
  async created() {
    await this.getAssociation();
    this.moment = moment;
  },
   watch: {
    "entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async printPdf() {
      let formData = new FormData();


    
      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);


      let data = `${this.VUE_APP_API_URL}/api/report/nid-report_pdf`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',

      });
      let formData = new FormData();


      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);



      await ApiService.post('report/nid-report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',

      });
      let formData = new FormData();


      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);


      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/nid-report_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Nidreport.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },


     async printPdfnext() {
      let formData = new FormData();


      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);
      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);


      let data = `${this.VUE_APP_API_URL}/api/report/nid-report_print_next`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdfnext() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
       
      });
      let formData = new FormData();


      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);
      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);



      await ApiService.post('report/nid-report_pdf_next', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTraineenext() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
       
      });
      let formData = new FormData();

      formData.set('entity', this.entity_id);
      formData.set('institute', this.institute_id);
      formData.set('startDate', this.start_date);
      formData.set('endDate', this.end_date);


      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/nid-report_export_next`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Nid Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async nidReport() {
      this.tabLoad = true;

      this.nidreportallstatus = true;
      this.nidreportstatus = false;
      this.nidliststatus = false;
      await ApiService.get(
        'report/nid-report-all?start_date=' +
        this.start_date +
        '&end_date=' +
        this.end_date
      )
        .then((response) => {
          this.nidReportAll = response.data.data.entities;
          this.TotalEnroll = response.data.data.TotalEnroll;
          this.TotalNid = response.data.data.TotalNid;
          this.tabLoad = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async nidList() {
      this.tabLoad = true;
      if (this.entity_id && this.institute_id == '') {
        this.nidreportstatus = true;
        this.nidliststatus = false;
        this.nidreportallstatus = false;
        await ApiService.get(
          'report/nid-report?entity=' +
          this.entity_id +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
        )
          .then((response) => {
            this.nidReportInfo = response.data.data.institutes;
            this.TotalEnroll = response.data.data.TotalEnroll;
            this.TotalNid = response.data.data.TotalNid;
            this.tabLoad = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else if (this.institute_id && this.entity_id) {
        this.nidreportstatus = false;
        this.nidliststatus = true;
        this.nidreportallstatus = false;

        await ApiService.get(
          'report/nid-list?entity=' +
          this.entity_id +
          '&institute=' +
          this.institute_id +
          '&startDate=' +
          this.start_date +
          '&endDate=' +
          this.end_date
        )
          .then((response) => {
            this.nidListInfo = response.data.data;
            this.nidListTotal = this.nidListInfo.length;
            this.tabLoad = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          text: 'Please Select Training Partner and Institute!',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
        this.tabLoad = false;
      }
    },

   async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
     async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    // async showNidReport() {
    //   this.load = true;
    //   await ApiService.get(
    //     "report/nidReport?tranche=" +
    //       this.tranche_id +
    //       "&entity=" +
    //       this.entity_id +
    //       "&type=" +
    //       this.report_type
    //   )
    //     .then((response) => {
    //       this.nidReport = response.data.data.coursedata;
    //       this.Totalenrollment = response.data.data.Totalenrollment;
    //       this.Totalcertification = response.data.data.Totalcertification;
    //       this.Totalassesment = response.data.data.Totalassesment;

    //       this.total_job_placement = response.data.data.total_job_placement;

    //       this.shownidReport = true;
    //       this.componentKey += 1;
    //       this.load = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    async btnCheckStatus() {
      if (this.entity_id) {
        this.btnCheck = true;
      } else {
        Swal.fire({
          title: 'Tranche and Training Type Need to be Select!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete!',
        });
      }
      // else popup
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
